@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import '../../styles/variables.scss';

.main-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  &__title {
    margin: 0;
    min-height: 250px;
    padding-bottom: 100px;
    text-transform: uppercase;
    font-size: 50px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
  &__icon {
    box-shadow: 4px 3px 9px 3px transparentize($templateBackgroundColorDarkNormal, 0.6);
    border-radius: 50%;
    border: 2px solid $templateBackgroundColor;
    padding: 15px;
    display: block;
    &:hover, &:active {
      fill: var(--gf-color-main);
    }
  }
}

.search-icon_home {
  font-size: 50px;
}
