@import '../../styles/variables.scss';

.navigation {
  &__container {
    z-index: 10;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    position: fixed;
    width: var(--app-width);
    height: $navigationHeight;
    background-color: $templateBackgroundColor;
    bottom: 0;
    border-top: 1px solid #ccc;
    .ant-radio {
      display: none;
    }
    .divition-line {
      height: ($navigationHeight - 12px);
      width: 1px;
      background-color: $navigationButtonColor;
      opacity: 0.6;
      align-self: center;
    }
    .search-icon_small {
      font-size: 30px;
    }
  }
  &__buttons {
    fill: $navigationButtonColor;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-right: 0px;
    &.ant-radio-wrapper-checked {
      fill: var(--gf-color-main);
    }
  }
}