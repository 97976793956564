@import '../../styles/variables.scss';

.formWrapper {
  width: 100%;
  height: 100vh;
  padding-top: 50px;
}

.loginForm {
  background-color: $templateBackgroundColor;
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  border-radius: $border-radius;
  box-shadow: 4px 3px 8px 3px var(--box-shadow-color);
  & label,
  &__forgot,
  &--switcher {
    color: $textColor; 
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
  &--switcher {
    display: flex;
    justify-content: center;
    & a {
      margin-left: 0.3em;
    }
  }
  &__title {
    text-align: center;
    text-transform: uppercase;
  }
  &__button {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    background-color: var(--gf-color-main);
    color: white;
    border: 2px solid var(--gf-color-main);
    text-transform: uppercase;
    font-weight: bold;
    height: 36px;
  }
  &__forgot,
  &__linkToRegistration {
    color: var(--gf-color-main);
  }
  &__forgot {
    float: right;
  }
}

.ant-col-rtl .loginForm__forgot {
  float: left;
}

.linkToSettings {
  display: block;
  margin: 50px auto 0;
  width: 100%;
  max-width: 300px;
}

@media screen and (orientation: landscape) {
  .formWrapper {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: $templateBackgroundColor;
  }
  .loginForm {
    max-width: 100%;
    margin: 0;
    padding: 20px;
    border: none;
    box-shadow: none;
  }
}
