@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import '../../styles/variables.scss';

.list {
  &--wrapper {
    height: 100vh;
    overflow: hidden;
  }
  &__list {
    &--null {
      box-shadow: 0px 5px 10px -3px var(--box-shadow-color);
      min-height: $listItemHeight;
      display: flex;
      justify-content: center;
      align-items: center;
      font: 500 20px 'Roboto', sans-serif;
      color: #999;
      text-transform: uppercase;
    }
    &--item {
      overflow: hidden;
    }
    &--item-exit {
      min-height: $listItemHeight;
      height: $listItemHeight;
      opacity: 1;
    }
    &--item-exit-active {
      opacity: 0;
      min-height: 0;
      height: 0;
      transition: opacity 220ms ease-out, height 280ms ease-in, min-height 280ms ease-in;
    }
    height: calc(100% - #{$headerHeight} - #{$navigationHeight});
    position: relative;
    overflow-y: auto;
  }
  &__header {
    width: 100%;
    min-height: $headerHeight;
    display: flex;
    justify-content: center;
    align-items: center;
    font: 600 24px 'Roboto', sans-serif;
    text-transform: uppercase;
    box-shadow: 0px 5px 10px -3px var(--box-shadow-color);
    background-color: #fff;
  }
  &__placeholder::before {
    content: $noFilmsText;
    height: 100%;
    width: var(--app-width);
    position: fixed;
    display:flex;
    justify-content: center;
    align-items: center;
    font: $generalFont;
    font-size: 36px;
    color: $textColor;
    text-transform: uppercase;
  }
  &__emptyBlock {
    width: 100%;
    height: $navigationHeight;
  }
}

@media screen and (orientation: landscape) {
  .list {
    &__list {
      // scrollbar hide
      scrollbar-width: none; // only-moz
      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }
}