@import "./variables.scss";

/* ANTD style patches */

/* fix for antd modal window when scroll hidden */
body.ant-scrolling-effect {
  width: 100% !important;
}

/* patch makes preview always comes from x 50% y 12% coords */
div.ant-image-preview {
  transform-origin: 50% 12% !important;
}

/* fix ant image in modal grows for 100% available width */
img.ant-image-preview-img {
  width: 100%;
  object-fit: scale-down;
}

a:hover {
  color: var(--gf-color-dark);
}

/* ant  sliders patches */
.ant-slider {
  width: 98%;
  padding-top: 6px;
  transform: translateY(50%);
  &:hover {
    .ant-slider-track {
      background-color: var(--gf-color-light);
    }
    .ant-slider-handle {
      border: solid $handleSize/6 var(--gf-color-main) !important;
    }
  }
  &-rail {
    display: none;
  }
  &-step {
    padding-left: 60px;
    border-radius: $sliderHeight * 0.5;
    height: $sliderHeight;
    background: #888;
  }
  &-track {
    background-color: var(--gf-color-main);
    height: $sliderHeight;
    z-index: 1;
  }
  &-dot {
    display: none;
  }
  &-handle {
    &-dragging {
      border: solid $handleSize/6 var(--gf-color-dark) !important;
      -webkit-box-shadow: 0 0 0 5px rgba(141, 141, 141, 0.12) !important;
      box-shadow: 0 0 0 5px rgba(141, 141, 141, 0.12) !important;
    }
    &:focus {
      border: solid $handleSize/6 var(--gf-color-main) !important;
      -webkit-box-shadow: 0 0 0 5px rgba(141, 141, 141, 0.12) !important;
      box-shadow: 0 0 0 5px rgba(141, 141, 141, 0.12) !important;
    }
    margin-top: -$handleSize/2;
    z-index: 2;
    transform: translate3d(-50%, $sliderHeight/2, 0px) !important;
    width: $handleSize;
    height: $handleSize;
    border: solid $handleSize/6 var(--gf-color-main) !important;
  }
  &-mark {
    top: -24px;
    left: calc(1.5%);
    width: calc(97%);
    &-text {
      width: 60px;
    }
  }
}

/* ant switch patches */
.ant-switch {
  transform: scale(1.2);
  &-checked {
    &:focus {
      -webkit-box-shadow: 0 0 0 2px rgba(140, 140, 140, 0.2) !important;
      box-shadow: 0 0 0 2px rgba(140, 140, 140, 0.2) !important;
    }
    background-color: var(--gf-color-main);
  }
}

/* ant button patches */
.ant-btn {
  margin-bottom: 8px;
  margin-right: 8px;
  &:hover,
  &:focus {
    color: var(--gf-color-main);
    background: rgba(140, 140, 140, 0.2);
    border-color: var(--gf-color-light);
  }
  &-lg {
    padding: 8px;
  }
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-left: 0;
}

/* ant select style patches */
.ant-select {
  &-focused,
  &:hover,
  &:focus {
    & .ant-select-selector {
      border-color: var(--gf-color-main) !important;
      -webkit-box-shadow: 0 0 0 2px rgba(140, 140, 140, 0.2) !important;
      box-shadow: 0 0 0 2px rgba(140, 140, 140, 0.2) !important;
    }
  }
  &-item-option {
    &-state {
      color: var(--gf-color-dark) !important;
    }
    &-selected {
      background-color: var(--gf-color-light) !important;
    }
  }
}

/* ant input style patches */
.ant-input {
  &:hover,
  &:focus {
    border-color: var(--gf-color-dark);
    box-shadow: none;
  }
  &-affix-wrapper {
    &-focused,
    &:not(.ant-input-affix-wrapper-disabled):hover,
    &:not(.ant-input-affix-wrapper-disabled):focus {
      border-color: var(--gf-color-light) !important;
      box-shadow: none;
    }
  }
}

/* ant checkbox style patches */
.ant-checkbox {
  &-checked &-inner {
    background-color: var(--gf-color-main) !important;
    border: none;
  }
  &::after,
  &-wrapper:hover &-inner,
  &-input:focus + &-inner {
    border-color: var(--gf-color-light) !important;
  }
}

/* ant list patches */
.ant-list {
  &-header {
    padding: 0px !important;
  }
  &-split
  &-item {
    box-shadow: 0 3px 4px rgba(0,0,0, 0.2);
  }
}