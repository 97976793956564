@import './variables.scss';

// TODO переделать все цвета тёмной темы через CSS variables (как сделано --box-shadow-color ниже)

/* Dark theme */
body {
  &.dark .theme,
  &.dark .theme h1,
  &.dark .theme h2,
  &.dark .theme h3,
  &.dark .theme h4,
  &.dark .theme h5,
  &.dark .theme h6 {
    background-color: $templateBackgroundColorDarkNormal !important;
    color: $textColorDarkBright !important;
  }
  &.dark .theme > svg {
    fill: $textColorDarkBright !important;
  }
  &.dark .theme > span {
    color: $textColorDarkNormal !important;
  }
  &.dark #root {
    background-color: $rootDarkColor !important;
  }
  &.dark {
    --box-shadow-color: #{ $shadowColorDark };
  }
  &.dark .navigation__container {
    border-top: 2px solid $templateBackgroundColorDarkBright;
    background-color: $templateBackgroundColorDarkNormal;
  }
}

/* Dark theme for antd */
body {
  &.dark .theme .ant-select-selector {
    background-color: $templateBackgroundColorDarkBright !important;
    color: $textColorDarkBright !important
  }
}