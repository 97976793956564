@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import '../../styles/variables.scss';

@mixin fontFrame($weight, $size, $color) {
    font: $weight $size 'Roboto', sans-serif;
    color: $color;
}

.list-item {
    width: 100%;
    min-height: $listItemHeight;
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
    box-shadow: 0px 5px 10px -3px var(--box-shadow-color);
    font: 400 18px 'Roboto', sans-serif;
    &__about {
        width: 80%;
        padding: 10px 0 5px;
        box-sizing: border-box;
    }
    &__name {
        margin-bottom: 10px;
    }
    &__second-name {
        min-height: 21px;
        color: $textColor;
        margin: 0;
    }
    &__genre {
        color: $textColor;
        margin: 0;
    }
    &__actions-container{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 70px;
    }
    &__rating {
        display: flex;
        & svg {
            position: relative;
            top: -2px;
        }
        @include fontFrame(400, 17px, var(--gf-color-main));
        margin-bottom: 5px;
    }
    &__rate {
        flex-grow: 1;
        text-align: center;
    }
    &__buttons {
        & .favorite {
            position: relative;
            top: 2px;
        }
        display: flex;
        justify-content: flex-end;
        padding: 0 3px;
        fill: $listsButtonColor;
    }
} 