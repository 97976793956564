@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import '../../styles/variables.scss';

.lists {
  &--wrapper {
    height: 100%;
    background-color: $templateBackgroundColor;
  }
  &__header {
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: 600 24px 'Roboto', sans-serif;
    text-transform: uppercase;
    box-shadow: 0px 5px 10px -3px var(--box-shadow-color);
    background-color: #fff;
  }
  &__link--color {
    color: rgb(112, 112, 112);
  }
  &__footer {
    position: fixed;
    bottom: 0;
    width: var(--app-width);
  }
  &__button__group {
    width: 100%;
    display: flex;
    justify-content: center;
    height: $navigationHeight + 52px;
    & button {
      width: 90%;
      font: 600 16px 'Roboto', sans-serif;
      background-color: transparent !important;
      line-height: 50%;
      border-radius: 10px;
      color: var(--gf-color-main);
      border: 2px solid var(--gf-color-main);
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}