@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import './variables.scss';
@import './antd_style_patches.scss';
@import './dark.scss';

body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  transition: .2s all ease-in;
  --box-shadow-color: #{ $shadowColor };
  overscroll-behavior-y: none;
}

button {
  cursor: pointer;
}

input, button {
  outline: none;
  background: transparent;
  border: none;
}

.App {
  --app-width: #{$appWidthDefault};
  height: 100%;
  width: var(--app-width);
}

.container {
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: $templateBackgroundColor;
}

.page {
  position: fixed;
  width: var(--app-width);
  height: 100%;
  // router animations
  &-enter {
    opacity: 0;
    z-index: 2;
    &-active {
      opacity: 1;
      transition: opacity 700ms;
    }
  }
  &-exit {
    top: 0px;
    width: 100% !important;
    z-index: 1;
    filter: blur(0px);
    opacity: 1;
    &-active {
      filter: blur(100px);
      opacity: 0;
      transition: opacity 700ms, filter 700ms;
    }
  }
}

@media screen and (orientation: landscape) {
  .App {
    margin: 0 auto;
    position: relative;
    box-shadow:0 1px 4px var(--box-shadow-color), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  }
  .App:before, .App:after {
    content:"";
      position:absolute;
      z-index:-1;
      box-shadow:0 0 20px rgba(0,0,0,0.8);
      top:10px;
      bottom:10px;
      left:0;
      right:0;
  }
  .App:after {
    right:10px;
    left:auto;
    transform:skew(8deg) rotate(3deg);
  }
  .page {
    width: var(--app-width) !important;
  }
  // fixes router animations for desktop
  .page-exit {
    .filmCard {
      &__poster__background {
          width: 100% !important;
      }
      &__footer {
        width: 100% !important;
      }
    }
    .settings {
      &__content {
        width: 100% !important;
      }
    }
    .navigation {
      &__container {
        width: 100% !important;
      }
    }
    .lists {
      &__footer {
        width: 100% !important;
      }
    }
  }

}

@media screen and (orientation: landscape) and (min-width: 769px) {
  .App {
    --app-width: #{$appWidthMinWidth769px};
  }
}
@media screen and (orientation: landscape) and (min-width: 1025px) {
  .App {
    --app-width: #{$appWidthMinWidth1025px};
  }
}
@media screen and (orientation: landscape) and (min-width: 1201px) {
  .App {
    --app-width: #{$appWidthMinWidth1201px};
  }
}