.loadingSpinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & span {
    font-size: 64px;
  }
  & svg {
    fill: var(--gf-color-main);
  }
  background: rgba(75, 75, 75, 0.5);
}