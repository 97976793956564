@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import '../../styles/variables.scss';

.settings {
  &--wrapper {
    overflow: hidden;
    min-width: 151px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $templateBackgroundColor;
  }
  &__header {
    z-index:1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: $headerHeight;
    box-shadow: 0px 5px 10px -3px var(--box-shadow-color);
    &--title {
      font: 600 24px 'Roboto', sans-serif;
      margin: 0 !important;
      text-transform: uppercase;
    }
  }
  &__content {
    position: fixed;
    padding-top: $headerHeight + 12px;
    padding-bottom: $navigationHeight + 12px;
    height: 100%;
    width: var(--app-width);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &--row {
      padding: 0px 1%;
      span {
        color: $textColor;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
      }
      &:first-of-type {
        padding-left: 0px;
        display: flex;
        justify-content: space-around;
      }
      padding-left: 6px;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &--color {
      width: 78%;
      transform: translateY(-3px);
      & .ant-slider-track {
        background: transparent !important;
      }
      & .ant-slider-step {
        background: linear-gradient(to right, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
      }
      & .ant-slider-handle {
        background-color: var(--gf-handle-color);
        border: solid $handleSize/6 #fff !important;
        box-shadow: rgba(0,0,0,0.37) 0px 1px 4px 0px;
      }
      &:hover .ant-slider-handle {
        border: solid $handleSize/6 var(--gf-handle-color) !important;
      }
    }
    &--slider {
      width: 78%;
    }
    &--select {
      // отключаем возможность набора
      .ant-select-selection-search input {
        visibility: hidden;
      }
      width: 80%;
    }
    &--login {
      font: 600 16px 'Roboto', sans-serif;
      margin: 0 auto;
      width: 90%;
      padding: 5px;
      & button {
        width: 100%;
        border-radius: 10px;
        background-color: var(--gf-color-main);
        color: white;
        border: 2px solid var(--gf-color-main);
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
      } 
    }
    &--counter {
      font: 500 18px 'Roboto', sans-serif;
      color: var(--gf-color-main);
      code {
        padding: 0.3em 0.6em;
      }
      &.warning {
        color: #ff4d4f;
      }
    }
    &--reset {
      font: 600 16px 'Roboto', sans-serif;
      width: 90%;
      border-radius: 10px;
      background-color: transparent;
      color: var(--gf-color-main);
      border: 2px solid var(--gf-color-main);
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}