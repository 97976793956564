@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import '../../styles/variables.scss';

.listLink--wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font: 400 18px 'Roboto', sans-serif;
    padding: 20px 25px;
    box-shadow: 0px 5px 10px -3px var(--box-shadow-color);
}

.listLink__title {
    margin: 0;
}

.listLink__length {
    margin: 0;
}