$shadowColor: rgba(58, 58, 58, 0.29);
$border-radius: 5px;
$generalFont: 400 16px 'Roboto', sans-serif;
$textColor: rgb(112, 112, 112);
$templateBackgroundColor: rgb(245, 245, 245);
$headerHeight: 50px;
$navigationHeight: 50px;
$navigationButtonColor: #888;
$listsButtonColor: #888;
$listItemHeight: 96px;
$noFilmsText: "Список пуст";
// sliders
$sliderHeight: 6px;
$handleSize: 24px;
// dark mode
$templateBackgroundColorDarkNormal: #222;
$templateBackgroundColorDarkBright: #333;
$textColorDarkNormal: #ccc;
$textColorDarkBright: #eee;
$shadowColorDark: rgba(200, 200, 200, 0.29);
$rootDarkColor: #1E1E1E;
$appWidthDefault: 100%;
$appWidthMinWidth769px: 45%;
$appWidthMinWidth1025px: 36%;
$appWidthMinWidth1201px: 32%;